<template>
  <div class="all">
    <HomeHeader active-index="/support"></HomeHeader>
    <div class="main">
      <h2>在线支持</h2>
      <h4>ONLINE</h4>
      <el-row :gutter="30" style="margin-top: 20px;">
        <el-col :span="6">
          <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=928194111&site=qq&menu=yes">
            <el-card shadow="hover"
                     style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
              <el-image :src="require('../../assets/Support/1.png')" lazy style="height: 5vw;width: 5vw;min-width: 48px;min-height: 48px"/>
              <p>在线客服</p>
            </el-card>
          </a>
        </el-col>
        <el-col :span="6">
          <el-card shadow="never"
                   style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
            <el-image :src="require('../../assets/Support/2.png')" lazy style="height: 3.9vw;width: 5vw;min-width: 48px;min-height: 37px;"/>
            <p>邮箱咨询</p>
            <p>gaoxd@toodo.com.cn</p>
          </el-card>
        </el-col>
      </el-row>

      <h2 style="margin-top: 20px;">电话支持</h2>
      <h4>PHONE</h4>
      <el-row :gutter="30" style="margin-top: 20px;">
        <el-col :span="6">
          <el-card shadow="never"
                   style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
            <el-image :src="require('../../assets/Support/3.png')" lazy style="width:3.95vw;height: 5vw;min-width: 38px;min-height: 48px;"/>
            <p>服务热线</p>
            <p>4001-600-891</p>
          </el-card>
        </el-col>
      </el-row>

      <h2 style="margin-top: 20px;">社交支持</h2>
      <h4>SOCIAL CONTACT</h4>
      <el-row :gutter="30" style="margin-top: 20px;">
        <el-col :span="6">
          <a target="_blank" href="https://weibo.com/p/1005056914272064/home?from=page_100505&mod=TAB#place">
            <el-card shadow="hover"
                     style="height: 18vw;min-height:173px;
                     display: flex; justify-content: center;align-items: center;text-align: center">
              <el-image :src="require('../../assets/Support/4.png')" lazy style="height: 4vw;width: 5vw;min-width: 48px;min-height: 38px;"/>
              <p>新浪微博</p>
            </el-card>
          </a>
        </el-col>
        <el-col :span="6">
          <el-card shadow="never"
                   style="height: 18vw;min-height:173px;display: flex; justify-content: center;align-items: center;text-align: center">
            <el-image :src="require('../../assets/Support/5.png')" lazy style="height: 9vw;width: 9vw;min-width: 86px;min-height: 86px;"/>
            <p>微信公众号</p>
          </el-card>
        </el-col>
      </el-row>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>

import Footer from "../../components/Footer";
import HomeHeader from "../../components/HomeHeader";

export default {
  name: "Support",
  data() {
    return {
      link: 'http://wpa.qq.com/msgrd?v=3&uin=1171723066&site=qq&menu=yes',
    }
  },
  components: {
    HomeHeader, Footer
  },
  props: {
    activeIndex: {
      type: String
    }
  },
  methods: {}
}
</script>

<style scoped>
.all {
  margin: 0;
  padding: 0;
  min-width: 960px;
}

.main {
  margin: 110px 5% 50px 5%;
  text-align: left;
}

/*.content img {*/
/*    height: 40px;*/
/*    width: 50px;*/
/*}*/

.router-link-active {
  text-decoration: none;
}

a {
  text-decoration: none;
}
</style>